import {FC, FormEvent, useState} from "react"
import Send from "../icons/send.svg"

export const SignupForm: FC<{}> = (props) => {
  const [state, setState] = useState<"waiting" | "saving" | "done">("waiting")
  const [email, setEmail] = useState("")

  const handleSubmit = async (ev: FormEvent) => {
    ev.preventDefault()

    if (!email || state === "done") {
      return
    }

    setState("saving")

    const result = await fetch("/api/submit", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({email, list: "apps_newsletter", source: "tomato2_landing"}),
    })

    setState("done")
  }

  return (
    <form className="newsletter" method="post" onSubmit={handleSubmit}>
      <div className="input">
        <input
          type="email"
          required
          placeholder="Enter your e-mail"
          name="email"
          value={email}
          onChange={(ev) => setEmail(ev.target.value)}
        />
      </div>

      <button
        className="button primary"
        disabled={state === "saving" || state === "done"}
      >
        <Send />
        <span>
          {state === "saving" ? "Saving..." : state === "done" ? "Done!" : "Sign up"}
        </span>
      </button>
    </form>
  )
}
