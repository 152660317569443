export const createAppStoreLink = (appId: string, campaign: string = "website") => {
  // const params = new URLSearchParams(window.location.search)
  // const source = params.get("utm_source")

  // if (source) {
  //   campaign += `_${source}`
  // }

  return `https://apps.apple.com/app/apple-store/id${appId}?pt=118478464&ct=${campaign}&mt=8`
}
