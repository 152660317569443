import cx from "classnames"
import {FC, useEffect, useState} from "react"
import Timing from "../icons/timing.svg"
import Stats from "../icons/stats.svg"
import Send from "../icons/send.svg"
import {Helmet} from "react-helmet"
import Download from "../icons/download.svg"
import Blocking from "../icons/blocking.svg"
import "../styles/index.scss"
import {SignupForm} from "../components/signup-form"
import {createAppStoreLink} from "../helpers/app-store"

function Index() {
  return (
    <main>
      <Helmet>
        <title>Tomato 2 - Pomodoro Technique Mac App</title>
        <link rel="icon" href="/link/to/favicon.png" />
      </Helmet>

      <ProductHuntBadge />

      <div className="hero">
        <div className="icon"></div>
        <h1>Tomato 2</h1>
        <h2>
          Mac app for the <em>Pomodoro Technique</em>. It's simple, beautiful and
          feature-packed.
        </h2>

        <div className="actions">
          <Button primary download href={createAppStoreLink("1494210770")}>
            Download
          </Button>
          <Button href="#main">Learn more</Button>
        </div>
      </div>

      <div className="main">
        <a id="main"></a>

        <aside className="left">
          <h4>Simple design</h4>
          <p>
            A Pomodoro app doesn't need much. Timer and some buttons. Everything else is a
            distraction. It's designed to help you stay focused.
          </p>

          <h4>Tomato and Break buttons</h4>
          <p>
            Two main buttons for two most common things you'll do in the app. Sometimes
            you want to start a Tomato, and sometimes you need a break.
          </p>
        </aside>

        <div className="image"></div>

        <aside className="right">
          <h4>Dark mode</h4>
          <p>
            Tomato 2 is carefully designed to look great both in light and dark mode. It
            will follow your system setting, so nothing to worry about.
          </p>

          <h4>Setting a goal</h4>
          <p>
            See the tiny text at the bottom? Click on it will let you set a goal for your
            current session. Stay focused on your task.
          </p>
        </aside>
      </div>

      <Feature
        name="timing"
        title="Timing modes"
        text="Some people prefer exactly 25-minute sessions, others like to keep working if
          it's going well. Choose what works for you. You can even sync the sessions to
          clock, taking break on 25th and then on 55th minute of each hour."
        icon={<Timing />}
      />

      <Feature
        name="stats"
        title="Weekly stats"
        text="Remember those “session goals” you can set for each session? With the weekly view, you can see at a glance exactly what you worked on throughout the week."
        icon={<Stats />}
      />

      <Feature
        name="blocking"
        title="Website blocking"
        text="Block websites of your choice during the session. Use it as a gentle reminder that you are “wired in”."
        icon={<Blocking />}
      />

      <div className="feature">
        <h3>
          <span>Let's stay in touch</span>
        </h3>

        <p>
          This app is made by Vojtech Rinik, a programmer and a productivity geek.{" "}
          <a href="https://twitter.com/_vojto" target="_blank">
            Find me on Twitter
          </a>
          , <a href="mailto:vojto@hey.com">send me an email</a>, or sign up for my
          newsletter, which I send out every 2 months:
        </p>

        <SignupForm />

        <p className="small">
          Newsletter contains updates on my apps, links to other apps I find useful,
          quality articles about productivity, and such.
        </p>
      </div>
    </main>
  )
}

const Feature: FC<{
  name: string
  title: string
  text: string
  icon: JSX.Element
}> = ({name, title, text, icon}) => {
  return (
    <div className="feature">
      <h3>
        {icon}
        <span>{title}</span>
      </h3>

      <p>{text}</p>

      <div className={cx("screenshot", name)}></div>
    </div>
  )
}

const Button: FC<{primary?: boolean; href?: string; download?: boolean}> = ({
  primary,
  href,
  children,
  download,
}) => {
  return (
    <a href={href} className={cx("button", primary && "primary")}>
      {download && <Download />}
      <span>{children}</span>
    </a>
  )
}

const ProductHuntBadge = () => {
  return (
    <div className="badge">
      <a
        href="https://www.producthunt.com/posts/tomato-2?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-tomato-2"
        target="_blank"
      >
        <img
          src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=278563&theme=light"
          alt="Tomato 2 - Delightful pomodoro app | Product Hunt"
          style={{width: "250px", height: "54px"}}
          width="250"
          height="54"
        />
      </a>
    </div>
  )
}

export default Index
